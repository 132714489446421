@import '../../App.scss';

.piano-main-container {
display: flex;
flex-direction: column;
align-items: center;
}

.piano-input-wrapper .pianoKey{
    cursor: pointer;

}
.piano-main-container{
    padding: 24px 16px 0px;
    position: sticky;
    top: 0;
    background: $background;
    z-index: 10;
    box-shadow: 0 8px 10px rgba($background,.75);
    border-bottom: 1px solid rgba($blue, .5);
    max-width: 640px;
    margin: 0 auto;
    transition: all .5s ease;
}

.select-keys-prompt {
    color: $blue;
}

.button-and-prompt-wrapper {
    padding: 1.5rem;
    padding-bottom: 24px;
}

.clear-button {
    background: rgba($blue,0);
    padding: 10px 30px;
    border-radius: 22px;
    border: 1px solid $red;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: $red;
    transition: all .5s ease;
    &:hover{
        border: 1px solid $red;
        color: $background;
        background: $red;
    }

  }

  .rendered-scale {
    // padding-bottom: 2rem;
    // transform: scale(0.8); 
    max-width: 360px;
    margin: 0 auto;
    padding-bottom: 64px;
    background: radial-gradient(rgba(0,0,0,1) 8%, rgba($background,0) 72%);
  }

    h2 {
    font-weight: 300;
    color: $type;
    }
    
.returned-scale-text {
    color: white
}

.piano-scale-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.piano-input-wrapper, .piano-scale-wrapper{
    width: 100%;
    max-width: 480px;
    display: flex;
    position: relative;
    justify-content: space-around;
    justify-items:stretch;
    aspect-ratio: 2.33/1;
    position: relative;

}
.pianoKey{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items:center;
    padding: 4px;
    border: 1px solid rgba($blue, .5);
    // border-bottom: 3px solid $type;
    background: $background;
    color: $blue;
    font-size: 12px;
    border-radius: 0 0 5% 5%;
    transition: all .3s ease;
    box-shadow: 0 8px 10px rgba(0,0,0,.75);
    &:hover{
        background: #5e9197;
    }
}

.rendered-scale .pianoKey{
    background: $background;
    border: none;
}

.white{
    width: calc(100%/7);
    float: left;
    // background: $type;
    // color: $background;
}

.black{
    width: calc(100%/14);
    height: 65%;
    position: relative;
    margin: 0 -15px;
    z-index: 1;
    float: left;

}
//this isnt particularly DRY but can be if we get "selected" added to the class list instead of a classlist separate from white/black/pianoKey
.whiteSelected{
    width: calc(100%/7);
    float: left;
    color: $background;

}

.blackSelected{
    width: calc(100%/14);
    height: 65%;
    position: relative;
    margin: 0 -15px;
    z-index: 1;
    float: left;
    color: $background;
    
}

.whiteSelected, .blackSelected, .rendered-scale .whiteSelected, .rendered-scale .blackSelected{
    background: $blue;
}


.potentialList{
    text-align:center;
    opacity: 1;
    transition: all .4s ease;
    position: relative;
    top: 0;
    padding: 24px 16px 24px;
    h2{
        margin-bottom: 24px;
        font-size: 16px;
        color: $blue;
        span{
            display: inline-block;
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 2px solid $blue;
            font-size: 24px
        } 
    }
    
}
.hide{
    opacity: 0;
    top: 40px;
}