@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&family=Roboto:wght@300;400&display=swap');

.glossary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 5rem 5rem 5rem;
}

.glossary-wiki-content-wrapper {
    font-size: 12px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 2rem;
    color: whitesmoke;
    margin-bottom: 3rem;
}

.glossary-card {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 1.5rem 1rem 1.5rem 1rem;
    text-align: center;
    width: 30rem;
    background: radial-gradient(rgba(104, 104, 104, 0.242) 8%, rgba(40, 44, 52,1) 72%);
   
}

.glossary-terms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
   
}



img {
    width: 40%;
    height: auto;
}