.menu-icon {
    display: none;
    color: #93f6fa;
        display: block;
        position: absolute;
        top: 25px;
        right: 20px;
        cursor: pointer;
        z-index: 1;
        @media  (min-width: 768px) {
            right: 20%;
        } 
        @media  (min-width: 1020px) {
            right: 25%;
        } 
        @media  (min-width: 1300px) {
            right: 30%;
        } 
        }


.menu-link {
    text-decoration: none;
    color: inherit;
}

.menu-list-items {
    padding-bottom: 1rem;

}

