@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Crimson+Pro:wght@500&family=Josefin+Sans:wght@700&family=Lato:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&family=Roboto:wght@300;400&display=swap');


$accent: rgba(14, 15, 16,1);
$background: rgba(40, 44, 52,1);
$type: rgba(222,222,222,1);
$red: rgba(248, 155, 156,1);
$orange: rgba(255, 180, 134,1);
$yellow: rgba(255, 240, 111,1);
$green: rgba(130, 250, 145,1);
$blue: rgba(147, 246, 250,1);


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

html{
  background: $background;
}

header{
  padding: 24px 24px 0;
  h1{
    color: $blue;
    font-size: 24px;
    font-weight: 300;
    text-align: center;

  }
  
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: whitesmoke;
  background-color: rgba(40, 41, 49, 0.834);
  font-size: 10px;
  z-index: 5;
}






